import { MAIN_URL } from "../utils/constants";

class MainApi {
  constructor({ baseUrl }) {
    this._BASE_URL = baseUrl;
  }

  _checkResponse(res) {
    if (res.ok) {
      return res.json();
    } else {
      const statusCode = res.status;
      return res.json().then((res) => {
        return Promise.reject({
          statusCode: statusCode,
          message: res.msg,
          detail: res.detail,
        });
      });
    }
  }

  getArticle({ translit_name }) {
    return fetch(`${MAIN_URL}/articles/no-auth/get-by-translit-name/${translit_name}`, {
      method: "GET",
      credentials: "include",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then(this._checkResponse);
  }

  getAllTags() {
    return fetch(
      `${MAIN_URL}/tags/get-all/?start=0&limit=10`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }
  getAllArticles() {
    return fetch(
      `${MAIN_URL}/articles/no-auth/get-all-new/`,
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }
  getAllArticlesByTag({ limit, start, currentTag }) {
    let params = {};
    if (start) params.start = start;
    if (limit) params.limit = limit;
    return fetch(
      `${MAIN_URL}/articles/no-auth/get-by-tags/${currentTag}?` +
      new URLSearchParams(params),
      {
        method: "GET",
        credentials: "include",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      }
    ).then(this._checkResponse);
  }
  sendPartnersForm({ name, email, phone, company_name, bank_name, bank_branch, bank_account, bank_account_name }) {
    return fetch(`https://d5dn1eam3tgju8hiftma.o2p3jdjj.apigw.yandexcloud.net/send-to-func`, {
      method: 'POST',
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        name: name,
        email: email,
        phone: phone,
        company_name: company_name,
        bank_name: bank_name,
        bank_branch: bank_branch,
        bank_account: bank_account,
        bank_account_name: bank_account_name,
        locale: 'uae'
      }),
    }).then(this._checkResponse);
  }
}

const mainApi = new MainApi({
  baseUrl: MAIN_URL,
});

export default mainApi;
