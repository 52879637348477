import { useState } from "react";
import CustomInput from "../../CustomInput/CustomInput";
import Checkbox from "../../Checkbox/Checkbox";
import bg from "../../../assets/images/partners/form/bg.webp";
import tl from "../../../assets/images/partners/form/left-top-stars.webp";
import bl from "../../../assets/images/partners/form/left-bottom-stars.webp";
import tr from "../../../assets/images/partners/form/right-top-stars.webp";
import br from "../../../assets/images/partners/form/right-bottom-stars.webp";
import "./PartnersForm.css";
import MiniPreloader from "../../MiniPreloader/MiniPreloader";
import { sendTextTgBot } from "../../../assets/utils/utils";
import { motion } from 'framer-motion';
import { CHAT_ID_PARTNERS } from "../../../assets/utils/constants";
import mainApi from "../../../assets/api/MainApi";

function PartnersForm({ valuesValidity, values, onChange, cleanForm }) {
  const [valuesCheck, setCheck] = useState(false);
  const [successTextVisible, setSuccessTextVisible] = useState(false);

  function handleChange() {
    setCheck(!valuesCheck)
  }

  const phoneValid = valuesValidity.phone.length > 0 ? valuesValidity.phone?.validState : true
  const isDisabled = !(valuesValidity.email?.validState && phoneValid);
  const [preloaders, setPreloaders] = useState(false)

  const handleSubmit = async (evt) => {
    if (valuesCheck && values.name && values.email && !isDisabled) {
      evt.preventDefault();
      mainApi
        .sendPartnersForm({
          name: values.name,
          email: values.email,
          phone: values.phone.replace(/\D/g, ""),
          company_name: values.company_name ? values.company_name : '',
          bank_name: values.bank_name ? values.bank_name : '',
          bank_branch: values.bank_branch ? values.bank_branch : '',
          bank_account: values.bank_account ? values.bank_account : '',
          bank_account_name: values.bank_holder_name ? values.bank_holder_name : ''
        })
        .then((res) => {
          console.log(res);
        })
        .catch((err) => {
          console.log(err);
        })
        .finally(() => {
          // setIsPrelouderVisible(false);
        });

      sendTextTgBot(CHAT_ID_PARTNERS, `________
New partners lead

Locale: UAE
Name: ${values.name}
Phone: ${values.phone}
Email: ${values.email}
Business: ${values.business_name ? values.business_name : '-'}
Bank Name: ${values.bank_name ? values.bank_name : '-'}
Bank Branch: ${values.bank_branch ? values.bank_branch : '-'}
Bank Account: ${values.bank_account ? values.bank_account : '-'}
Bank Holder Name: ${values.bank_holder_name ? values.bank_holder_name : '-'}
________`, setPreloaders)
      cleanForm()
      setSuccessTextVisible(true)
    } else {
      evt.preventDefault();
    }
  };

  return (
    <div className="partners-form">
      <p className="partners-form__title">open new horizons</p>
      <p className="partners-form__subtitle">for your business and partners</p>

      <form className="partners-form__form" onSubmit={handleSubmit}>
        <div className="partners-form__container">
          <p className="partners-form__form-title">Registration form</p>
          <div className="partners-form__inputs-block">
            <CustomInput
              name="name"
              label="Full name"
              placeholder="Full name"
              value={values.name}
              onChange={onChange}
            />
            <CustomInput
              name="email"
              label="E-mail"
              placeholder="E-mail"
              value={values.email}
              onChange={onChange}
            />
            <CustomInput
              name="phone"
              label="Phone number (optional)"
              placeholder="Phone number (optional)"
              value={values.phone}
              onChange={onChange}
            />
            <CustomInput
              name="business_name"
              label="Business name (optional)"
              placeholder="Business name (optional)"
              value={values.business_name}
              onChange={onChange}
            />
            <CustomInput
              name="bank_name"
              label="bank Name (optional)"
              placeholder="Bank Name (optional)"
              value={values.bank_name}
              onChange={onChange}
            />
            <CustomInput
              name="bank_branch"
              label="Bank Branch (optional)"
              placeholder="Bank Branch (optional)"
              value={values.bank_branch}
              onChange={onChange}
            />
            <CustomInput
              name="bank_account"
              label="Bank Account (optional)"
              placeholder="Bank Account (optional)"
              value={values.bank_account}
              onChange={onChange}
            />
            <CustomInput
              name="bank_holder_name"
              label="Bank Holder Name (optional)"
              placeholder="Bank Holder Name (optional)"
              value={values.bank_holder_name}
              onChange={onChange}
            />
          </div>
          <Checkbox
            name="confirm"
            label={`I confirm that I have read and agree with  <a href='/#' target='_blank'>the cooperation agreement</a>`}
            checked={valuesCheck}
            onChange={handleChange}
          />
          <button className={`partners-form__submit-btn ${valuesCheck && values.name && values.email && !isDisabled ? 'partners-form__submit-btn_submit' : ''}`} type="submit">
            {preloaders ? <MiniPreloader /> : '  Become our partner'}
          </button>
          {successTextVisible ?
            <motion.div
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 2.0 }}
              className="partners-form__success-text"
            >
              successfully sent your application for partnership
            </motion.div>
            : null}
        </div>
      </form>

      <img className="partners-form__bg" src={bg} alt="" />
      <img
        className="partners-form__stars partners-form__stars_type_top-left"
        src={tl}
        alt=""
      />
      <img
        className="partners-form__stars partners-form__stars_type_bottom-left"
        src={bl}
        alt=""
      />
      <img
        className="partners-form__stars partners-form__stars_type_top-right"
        src={tr}
        alt=""
      />
      <img
        className="partners-form__stars partners-form__stars_type_bottom-right"
        src={br}
        alt=""
      />
    </div>
  );
}

export default PartnersForm;
