import pci from '../../assets/images/footer/pci.png';
import master from '../../assets/images/footer/mastercard.png';
import visa from '../../assets/images/footer/visa.png';
// import unicredit from '../../assets/images/footer/unicredit.png';
import secure from '../../assets/images/footer/secure.png';
// import insta from '../../assets/images/footer/insta.png';
// import linkin from '../../assets/images/footer/in.png';
// import tube from '../../assets/images/footer/tube.png';
// import fb from '../../assets/images/footer/fb.png';
// import tiktok from '../../assets/images/footer/tiktok.png';
import magnati from '../../assets/images/footer/magnati.png';
import { BulgariaFlag, RomaniaFlag, SaudiArabiaFlag, UaeFlag } from '../icons/icons';
import tube_blog from '../../assets/images/blog/tube.png'
import tiktok_blog from '../../assets/images/blog/tiktok.png'
import insta_blog from '../../assets/images/blog/inst.png'
import fb_blog from '../../assets/images/blog/fb.png'
import linkin_blog from '../../assets/images/blog/linkin.png'
import { getRandomId } from './utils';

const importAll = (requireContext) =>
    requireContext
        .keys()
        .sort((a, b) => {
            const numA = parseInt(a.match(/item(\d+)/)[1], 10); // Use [1] to get the correct match
            const numB = parseInt(b.match(/item(\d+)/)[1], 10);
            return numA - numB;
        })
        .map((key) => requireContext(key)); // Ensure correct image import
const flags = importAll(require.context('../../assets/images/flags', false, /item\d+\.svg$/));

export const MAIN_URL = 'https://uae-sellavi-strapi.cabahub.com/api';
export const SELLAVI_API_URL = 'https://uae.sellavi.com'
export const PHOTO_URL = 'https://uae-sellavi-strapi.cabahub.com/uploads';

export const HEADING_BLOCK = 'heading';
export const PARAGRAPH_BLOCK = 'paragraph';
export const LIST_BLOCK = 'list';
export const ORDERED_LIST_FORMAT = 'ordered';
export const IMAGE_BLOCK = 'image';
export const QUOTE_BLOCK = 'quote';
export const CODE_BLOCK = 'code';
export const CHILD_TYPE_TEXT = 'text';
export const CHILD_TYPE_LINK = 'link';
export const CHILD_TYPE_LIST_ITEM = 'list-item';
export const TITLE_VAR = '{{TITLE}}';
export const GOOD_TITLE_VAR = '{{GOOD}}';
export const BAD_TITLE_VAR = '{{BAD}}';
export const PROMO_START_VAR = '{{PROMO_START}}';
export const CHAT_ID = '-1001990371125'
export const BOT_TOKEN = '6671687003:AAG-keX0Lch_FC_lduj4fzC6JBcyEOgL0KU';
export const CHAT_ID_PARTNERS = '-1002169377168'

export const CARDS_LIMIT = 9
export const productIdStorageKey = 'like'

// export const FOOTER_LINKS = [
//     {
//         title: 'Price',
//         link: '/price'
//     },
//     {
//         title: 'Partners',
//         link: '/partners'
//     },
//     {
//         title: 'How to thrive in e-commerce',
//         link: '/blog'
//     },
// ]
export const SOCIAL_BLOG = [
    {
        link: '',
        img: tube_blog,
    },
    {
        link: '',
        img: insta_blog,
    },
    {
        link: '',
        img: tiktok_blog,
    },
    {
        link: '',
        img: fb_blog,
    },
    {
        link: '',
        img: linkin_blog,
    },
]

export const FOOTER_LINKS_COLUMN = [
    {
        title: 'Get to know us',
        name: 'column1',
        subtitle: [
            {
                title: 'About Us',
                link: '/about'
            },
            {
                title: 'Terms & Conditions',
                link: ''
            },
            {
                title: 'Privacy Policy',
                link: ''
            },
            {
                title: 'Affiliates Program',
                link: ''
            },
        ],
        link: ''
    },
    {
        title: 'Abilities',
        name: 'column2',
        subtitle: [
            {
                title: 'Aquiring',
                link: ''
            },
            {
                title: 'Design & Branding',
                link: ''
            },
            {
                title: 'Booking & Digital Products',
                link: ''
            },
            {
                title: 'API & Webhook',
                link: ''
            },
            {
                title: 'Demo Version',
                link: ''
            },
        ],
        link: ''
    },
    {
        title: 'Sales & Marketing',
        name: 'column3',
        subtitle: [
            {
                title: 'Instagram Shopping',
                link: ''
            },
            {
                title: 'Facebook',
                link: ''
            },
            {
                title: 'TikTok for Business',
                link: ''
            },
            {
                title: 'Google SEO',
                link: ''
            }
        ],
        link: ''
    },
    {
        title: 'Support',
        name: 'column4line1',
        subtitle: [
            {
                title: 'Help Center',
                link: ''
            },
        ],
        link: ''
    },
    {
        title: 'Contact info',
        name: 'column4line2',
        subtitle: [
            {
                title: 'uae@sellavi.com',
                link: ''
            },
            // {
            //     title: 'support@sellavi.com',
            //     link: ''
            // },
        ],
        link: ''
    },
    {
        title: '+359 2 4927 777',
        name: 'column4line3',
        link: ''
    },
]

export const FOOTER_NAV_LINKS = [
    {
        path: '/about',
        title: 'About us'
    },
    {
        path: '/prices',
        title: 'Prices'
    },
    {
        path: '/partners',
        title: 'Affiliate program'
    },
    {
        path: '/blog',
        title: 'How to thrive in e-commerce',
        mobileTitle: 'How to do business',
    },
    // {
    //     path: '#',
    //     title: 'Free trial'
    // },
]

export const FOOTER_CONTACTS = [
    {
        title: 'uae@sellavi.com',
        link: 'mailto:uae@sellavi.com'
    },
    // {
    //     title: 'support@sellavi.com',
    //     link: 'mailto:support@sellavi.com'
    // },
]

export const FOOTER_LOGO_COLOR = [
    {
        name: 'master',
        logo: master,
    },
    {
        name: 'visa',
        logo: visa
    },
    {
        name: 'magnati',
        logo: magnati,
    },
    {
        name: 'secure',
        logo: secure
    },
    {
        name: 'pci',
        logo: pci
    },
]

// export const FOOTER_LOGO_SOCIAL = [
//     {
//         logo: insta,
//     },
//     {
//         logo: fb
//     },
//     {
//         logo: linkin
//     },
//     {
//         logo: tiktok
//     },
//     {
//         logo: tube
//     },
// ]

export const INFO_EMAIL = 'uae@sellavi.com'

export const THEME_LIGHT_TYPE = 'light'
export const THEME_DARK_TYPE = 'dark'

export const HEADER_LINKS = [
    // {
    //     path: '/',
    //     name: 'Home'
    // },
    {
        path: '/about',
        name: 'About us'
    },
    {
        path: '/prices',
        name: 'Prices'
    },
    {
        path: '/partners',
        name: 'Affiliate program'
    },
    {
        path: '/blog',
        name: 'How to thrive in e-commerce'
    },
]

export const MAIN_NAV_LINKS = [
    {
        path: 'start',
        name: 'Start trial'
    },
    {
        path: 'reviews',
        name: 'Customer reviews'
    },
    {
        path: 'steps',
        name: 'Setup steps'
    },
    {
        path: 'features',
        name: 'Features'
    },
    {
        path: 'ai',
        name: 'AI commerce'
    },
    {
        path: 'examples',
        name: 'Examples'
    },
    {
        path: 'benefits',
        name: 'Benefits'
    },
]

export const UAE_PHONE_CODE = { icon: UaeFlag, value: '+971' };
export const SAUDI_PHONE_CODE = { icon: SaudiArabiaFlag, value: '+966' };
export const ROMANIA_PHONE_CODE = { icon: RomaniaFlag, value: '+40' };
export const BULGARIA_PHONE_CODE = { icon: BulgariaFlag, value: '+359' };

export const PHONE_CODES_LIST = [
    { icon: flags[0], value: '+354' },
    { icon: flags[1], value: '+54' },
    { icon: flags[2], value: '+213' },
    { icon: flags[3], value: '+93' },
    { icon: flags[4], value: '+971' },
    { icon: flags[5], value: '+297' },
    { icon: flags[6], value: '+968' },
    { icon: flags[7], value: '+994' },
    { icon: flags[8], value: '+20' },
    { icon: flags[9], value: '+251' },
    { icon: flags[10], value: '+353' },
    { icon: flags[11], value: '+372' },
    { icon: flags[12], value: '+376' },
    { icon: flags[13], value: '+244' },
    { icon: flags[14], value: '+1264' },
    { icon: flags[15], value: '+61' },
    { icon: flags[16], value: '+43' },
    { icon: flags[17], value: '+1268' },
    { icon: flags[18], value: '+853' },
    { icon: flags[19], value: '+1246' },
    { icon: flags[20], value: '+1242' },
    { icon: flags[21], value: '+675' },
    { icon: flags[22], value: '+92' },
    { icon: flags[23], value: '+595' },
    { icon: flags[24], value: '+970' },
    { icon: flags[25], value: '+973' },
    { icon: flags[26], value: '+507' },
    { icon: flags[27], value: '+55' },
    { icon: flags[28], value: '+375' },
    { icon: flags[29], value: '+1441' },
    { icon: flags[30], value: '+359' },
    { icon: flags[31], value: '+1670' },
    { icon: flags[32], value: '+229' },
    { icon: flags[33], value: '+32' },
    { icon: flags[34], value: '+354' },
    { icon: flags[35], value: '+1' },
    { icon: flags[36], value: '+48' },
    { icon: flags[37], value: '+387' },
    { icon: flags[38], value: '+591' },
    { icon: flags[39], value: '+501' },
    { icon: flags[40], value: '+680' },
    { icon: flags[41], value: '+267' },
    { icon: flags[42], value: '+975' },
    { icon: flags[43], value: '+226' },
    { icon: flags[44], value: '+257' },
    { icon: flags[45], value: '+850' },
    { icon: flags[46], value: '+240' },
    { icon: flags[47], value: '+45' },
    { icon: flags[48], value: '+49' },
    { icon: flags[49], value: '+670' },
    { icon: flags[50], value: '+228' },
    { icon: flags[51], value: '+1767' },
    { icon: flags[52], value: '+1809' },
    { icon: flags[53], value: '+7' },
    { icon: flags[54], value: '+593' },
    { icon: flags[55], value: '+291' },
    { icon: flags[56], value: '+33' },
    { icon: flags[57], value: '+298' },
    { icon: flags[58], value: '+689' },
    { icon: flags[59], value: '+594' },
    { icon: flags[60], value: '+508' },
    { icon: flags[61], value: '+39' },
    { icon: flags[62], value: '+63' },
    { icon: flags[63], value: '+679' },
    { icon: flags[64], value: '+358' },
    { icon: flags[65], value: '+238' },
    { icon: flags[66], value: '+500' },
    { icon: flags[67], value: '+220' },
    { icon: flags[68], value: '+242' },
    { icon: flags[69], value: '+243' },
    { icon: flags[70], value: '+57' },
    { icon: flags[71], value: '+506' },
    { icon: flags[72], value: '+1473' },
    { icon: flags[73], value: '+299' },
    { icon: flags[74], value: '+995' },
    { icon: flags[75], value: '+590' },
    { icon: flags[76], value: '+1671' },
    { icon: flags[77], value: '+592' },
    { icon: flags[78], value: '+7' },
    { icon: flags[79], value: '+509' },
    { icon: flags[80], value: '+82' },
    { icon: flags[81], value: '+31' },
    { icon: flags[82], value: '+599' },
    { icon: flags[83], value: '+382' },
    { icon: flags[84], value: '+504' },
    { icon: flags[85], value: '+686' },
    { icon: flags[86], value: '+253' },
    { icon: flags[87], value: '+996' },
    { icon: flags[88], value: '+245' },
    { icon: flags[89], value: '+224' },
    { icon: flags[90], value: '+1' },
    { icon: flags[91], value: '+233' },
    { icon: flags[92], value: '+241' },
    { icon: flags[93], value: '+855' },
    { icon: flags[94], value: '+420' },
    { icon: flags[95], value: '+263' },
    { icon: flags[96], value: '+237' },
    { icon: flags[97], value: '+974' },
    { icon: flags[98], value: '+1345' },
    { icon: flags[99], value: '+269' },
    { icon: flags[100], value: '+381' },
    { icon: flags[101], value: '+225' },
    { icon: flags[102], value: '+965' },
    { icon: flags[103], value: '+385' },
    { icon: flags[104], value: '+254' },
    { icon: flags[105], value: '+682' },
    { icon: flags[106], value: '+599' },
    { icon: flags[107], value: '+371' },
    { icon: flags[108], value: '+266' },
    { icon: flags[109], value: '+856' },
    { icon: flags[110], value: '+961' },
    { icon: flags[111], value: '+370' },
    { icon: flags[112], value: '+231' },
    { icon: flags[113], value: '+218' },
    { icon: flags[114], value: '+423' },
    { icon: flags[115], value: '+262' },
    { icon: flags[116], value: '+352' },
    { icon: flags[117], value: '+250' },
    { icon: flags[118], value: '+40' },
    { icon: flags[119], value: '+261' },
    { icon: flags[120], value: '+44' },
    { icon: flags[121], value: '+960' },
    { icon: flags[122], value: '+356' },
    { icon: flags[123], value: '+265' },
    { icon: flags[124], value: '+60' },
    { icon: flags[125], value: '+223' },
    { icon: flags[126], value: '+389' },
    { icon: flags[127], value: '+692' },
    { icon: flags[128], value: '+596' },
    { icon: flags[129], value: '+262' },
    { icon: flags[130], value: '+230' },
    { icon: flags[131], value: '+222' },
    { icon: flags[132], value: '+1' },
    { icon: flags[133], value: '+1684' },
    { icon: flags[134], value: '+1340' },
    { icon: flags[135], value: '+976' },
    { icon: flags[136], value: '+1664' },
    { icon: flags[137], value: '+880' },
    { icon: flags[138], value: '+51' },
    { icon: flags[139], value: '+691' },
    { icon: flags[140], value: '+95' },
    { icon: flags[141], value: '+373' },
    { icon: flags[142], value: '+212' },
    { icon: flags[143], value: '+377' },
    { icon: flags[144], value: '+258' },
    { icon: flags[145], value: '+52' },
    { icon: flags[146], value: '+264' },
    { icon: flags[147], value: '+27' },
    { icon: flags[148], value: '+211' },
    { icon: flags[149], value: '+674' },
    { icon: flags[150], value: '+505' },
    { icon: flags[151], value: '+977' },
    { icon: flags[152], value: '+227' },
    { icon: flags[153], value: '+234' },
    { icon: flags[154], value: '+683' },
    { icon: flags[155], value: '+47' },
    { icon: flags[156], value: '+672' },
    { icon: flags[157], value: '+351' },
    { icon: flags[158], value: '+81' },
    { icon: flags[159], value: '+46' },
    { icon: flags[160], value: '+41' },
    { icon: flags[161], value: '+503' },
    { icon: flags[162], value: '+685' },
    { icon: flags[163], value: '+381' },
    { icon: flags[164], value: '+232' },
    { icon: flags[165], value: '+221' },
    { icon: flags[166], value: '+357' },
    { icon: flags[167], value: '+248' },
    { icon: flags[168], value: '+966' },
    { icon: flags[169], value: '+590' },
    { icon: flags[170], value: '+239' },
    { icon: flags[171], value: '+290' },
    { icon: flags[172], value: '+1869' },
    { icon: flags[173], value: '+1758' },
    { icon: flags[174], value: '+590' },
    { icon: flags[175], value: '+599' },
    { icon: flags[176], value: '+378' },
    { icon: flags[177], value: '+1784' },
    { icon: flags[178], value: '+94' },
    { icon: flags[179], value: '+421' },
    { icon: flags[180], value: '+386' },
    { icon: flags[181], value: '+268' },
    { icon: flags[182], value: '+249' },
    { icon: flags[183], value: '+597' },
    { icon: flags[184], value: '+677' },
    { icon: flags[185], value: '+252' },
    { icon: flags[186], value: '+992' },
    { icon: flags[187], value: '+886' },
    { icon: flags[188], value: '+66' },
    { icon: flags[189], value: '+255' },
    { icon: flags[190], value: '+676' },
    { icon: flags[191], value: '+1649' },
    { icon: flags[192], value: '+1868' },
    { icon: flags[193], value: '+216' },
    { icon: flags[194], value: '+688' },
    { icon: flags[195], value: '+90' },
    { icon: flags[196], value: '+993' },
    { icon: flags[197], value: '+690' },
    { icon: flags[198], value: '+681' },
    { icon: flags[199], value: '+678' },
    { icon: flags[200], value: '+502' },
    { icon: flags[201], value: '+58' },
    { icon: flags[202], value: '+673' },
    { icon: flags[203], value: '+256' },
    { icon: flags[204], value: '+380' },
    { icon: flags[205], value: '+598' },
    { icon: flags[206], value: '+998' },
    { icon: flags[207], value: '+30' },
    { icon: flags[208], value: '+34' },
    { icon: flags[209], value: '+212' },
    { icon: flags[210], value: '+856' },
    { icon: flags[211], value: '+65' },
    { icon: flags[212], value: '+687' },
    { icon: flags[213], value: '+64' },
    { icon: flags[214], value: '+36' },
    { icon: flags[215], value: '+963' },
    { icon: flags[216], value: '+1876' },
    { icon: flags[217], value: '+374' },
    { icon: flags[218], value: '+967' },
    { icon: flags[219], value: '+964' },
    { icon: flags[220], value: '+98' },
    { icon: flags[221], value: '+972' },
    { icon: flags[222], value: '+39' },
    { icon: flags[223], value: '+91' },
    { icon: flags[224], value: '+62' },
    { icon: flags[225], value: '+44' },
    { icon: flags[226], value: '+1284' },
    { icon: flags[227], value: '+246' },
    { icon: flags[228], value: '+962' },
    { icon: flags[229], value: '+84' },
    { icon: flags[230], value: '+260' },
    { icon: flags[231], value: '+44' },
    { icon: flags[232], value: '+235' },
    { icon: flags[233], value: '+350' },
    { icon: flags[234], value: '+56' },
    { icon: flags[235], value: '+236' },
    { icon: flags[236], value: '+86' },
].map((item)=>({
    ...item,
    _id: getRandomId()
}));

export const initialSignupValues = {
    code: PHONE_CODES_LIST[4],
    email: '',
    phone: '',
    password: '',
};


export const initialJoinValues = {
    name: '',
    email: '',
    phone: '',
    linkedin: '',
    position: '',
    why: '',
    comments: '',
    bank_name: '',
    bank_branch: '',
    bank_account: '',
    bank_holder_name: ''
};

export const initialSugnupValidity = {
    email: {},
    phone: {},
    password: {},
    name: {},
    business: {},
    occupation: {},
    confirm: {},
    linkedin: {},
    position: {},
    why: {},
    comments: {},
    bank_name: {},
    bank_branch: {},
    bank_account: {},
    bank_holder_name: {}
};
